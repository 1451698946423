import React from 'react';

function Mission({ nbr }) {
  return (
    <div
      className={` flex flex-col ${
        !(nbr % 2) ? ' md:flex-row-reverse' : 'md:flex-row'
      } gap-10 px-[6%] items-center`}
    >
      <div className=''>
        <div className='h-32 w-32 rounded-full bg-blue-950 flex items-center justify-center text-2xl text-white'>
          {nbr}
        </div>
      </div>
      <div>
        <h1 className='text-xl font-semibold mb-4'>
          freestar Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </h1>
        <p>
          freestar Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nulla vel viverra est, tristique consequat dolor. Maecenas sapien
          mauris, auctor sit amet orci vel, pretium cursus arcu. Aliquam erat
          volutpat. Sed ex nulla, fermentum a venenatis eu, consequat eget
          magna. Suspendisse lobortis, nisl a malesuada vestibulum, nibh turpis
          tincidunt orci,
        </p>
      </div>
    </div>
  );
}

export default Mission;
