import Mission from './components/Mission';
import Services from './components/Services';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { HiMail } from 'react-icons/hi';
import { MdLocationOn } from 'react-icons/md';

function App() {
  const nbrs = [1, 2, 3];
  return (
    <div className='font-satoshi'>
      <header className=' h-screen'>
        <nav className='flex justify-between items-center z-50 py-4 fixed top-0 left-0 right-0 bg-white shadow-md px-[5%]'>
          <div>
            <img src='/image001.png' className=' h-16' alt='logo' />
          </div>
          <ul className=' hidden md:flex gap-7 text-lg '>
            <li>Services</li>
            <li>A propos</li>
            <li>Contact</li>
          </ul>
          <div className=' text-3xl md:hidden'>
            <AiOutlineMenu />
          </div>
        </nav>
        <div className=' h-full justify-center flex-col-reverse md:flex-row flex gap-10'>
          <div className=' px-[5%] md:h-full md:pl-10 flex flex-col items-start justify-center gap-4 md:gap-7 md:w-1/2'>
            <h1 className=' text-5xl lg:text-7xl font-bold'>
              Lorem ipsum dolor sit amet
            </h1>
            <p>Lorem ipsum dolor sit anet conception argument elit</p>
            <button className=' bg-orange-500 py-4 px-14 text-white'>
              Contactez nous
            </button>
          </div>
          <div className=' bg-slate-400 md:w-1/2 h-1/2 md:h-full'></div>
        </div>
      </header>

      <section className=' mt-24'>
        <h2 className=' text-center mb-5'>Services</h2>
        <h3 className=' text-orange-600 text-center mb-14 text-xl font-semibold'>
          Lorem ipsum dolor sit anet conception argument elit
        </h3>
        <div className='flex flex-col md:flex-row justify-center gap-7 px-10'>
          {nbrs.map((nbr) => {
            return <Services key={nbr} />;
          })}
        </div>
      </section>

      <section className=' h-screen flex-col md:flex-row flex gap-10 md:gap-6 justify-center items-center'>
        <div className='grid grid-cols-2 w-[350px] aspect-square lg:w-[550px] gap-3 grid-rows-2'>
          <div className=' row-span-2 flex items-center'>
            <img
              src='/pexels-fauxels-3184433.jpg'
              alt='bonjour'
              className='w-full aspect-square object-cover'
            />
          </div>
          <div className=' bg-slate-800 aspect-square'>
            <img
              src='/pexels-fauxels-3182829.jpg'
              alt='bonjour'
              className='w-full h-full object-cover'
            />
          </div>
          <div className=' bg-slate-800 aspect-square'>
            <img
              src='/pexels-fauxels-3184433.jpg'
              alt='bonjour'
              className='w-full h-full object-cover'
            />
          </div>
        </div>
        <div className=' w-3/4 md:w-1/3'>
          <h2 className=' text-center md:text-start text-2xl text-orange-600 font-semibold mb-4'>
            Valeur
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel
            viverra est, tristique consequat dolor. Maecenas sapien mauris,
            auctor sit amet orci vel, pretium cursus arcu. Aliquam erat
            volutpat.
          </p>
        </div>
      </section>

      <section className=' mt-20 py-32 text-center text-white bg-slate-700'>
        <h3 className='text-2xl font-bold mb-4'>Pourquoi nous choisir ?</h3>
        <p className=' px-[5%]'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel
          viverra est, tristique consequat dolor. Maecenas sapien mauris, auctor
          sit amet orci vel, pretium cursus arcu. Aliquam erat volutpat.Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel viverra
          est, tristique consequat dolor. Maecenas sapien mauris, auctor sit
          amet orci vel, pretium cursus arcu. Aliquam erat volutpat.
        </p>
      </section>

      <section className=' flex flex-col md:flex-row items-center justify-center mt-32 gap-10 px-[6%]'>
        <div className=' md:w-1/2'>
          <h2 className='text-xl text-orange-500 mb-10'>A propos</h2>
          <p className=''>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel
            viverra est, tristique consequat dolor. Maecenas sapien mauris,
            auctor sit amet orci vel, pretium cursus arcu. Aliquam erat
            volutpat.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Nulla vel viverra est, tristique consequat dolor. Maecenas sapien
            mauris, auctor sit amet orci vel, pretium cursus arcu. Aliquam erat
            volutpat.
          </p>
        </div>
        <div className='relative'>
          <div className=' h-[300px] md:h-[400px] aspect-square bg-slate-700'>
            <img
              src='/pexels-canva-studio-3153201.jpg'
              alt='bonjour'
              className='h-full object-cover'
            />
          </div>
          <div className=' hidden sm:block h-60 md:h-80 aspect-square bg-orange-500 absolute -right-10 -bottom-10 -z-10'></div>
        </div>
      </section>

      <section className=' mt-32'>
        <h2 className=' text-center mb-5'>Mission</h2>
        <h3 className=' text-orange-600 text-center mb-14 text-xl font-semibold'>
          Lorem ipsum dolor sit anet conception argument elit
        </h3>
        <div className='flex flex-col gap-28'>
          {nbrs.map((nbr) => {
            return <Mission nbr={nbr} key={nbr} />;
          })}
        </div>
      </section>

      <section className=' flex flex-col lg:flex-row items-center justify-center mt-32 gap-20 px-[6%]'>
        <div className='relative'>
          <div className='h-[350px] sm:h-[400px] aspect-square flex flex-col justify-center items-center bg-slate-700'>
            <h3 className=' text-white text-2xl text-center font-semibold mb-10'>
              Contacts
            </h3>
            <div className='flex mb-6 gap-4 items-center'>
              <div className='text-2xl text-white'>
                <BsFillTelephoneFill />
              </div>
              <p className='text-white'>Lorem ipsum amet</p>
            </div>
            <div className='flex mb-6 gap-4 items-center'>
              <div className=' text-3xl text-white'>
                <HiMail />
              </div>
              <p className='text-white'>Lorem ipsum amet</p>
            </div>
            <div className='flex gap-4 items-center'>
              <div className=' text-3xl text-white'>
                <MdLocationOn />
              </div>
              <p className='text-white'>Lorem ipsum amet</p>
            </div>
          </div>
          <div className=' hidden sm:block h-80 aspect-square bg-orange-500 absolute -left-10 -top-10 -z-10'></div>
        </div>
        <form className=' w-[350px] md:w-[450px]'>
          <h3 className=' text-center text-lg mb-8'>Laissez Nous Un Message</h3>
          <div className=''>
            <div className='mb-5'>
              <input
                className='w-full bg-slate-100 p-5 text-slate-500'
                type='text'
                placeholder='Nom'
              />
            </div>
            <div className='mb-5'>
              <input
                className='w-full bg-slate-100 text-slate-500 p-5'
                type='email'
                placeholder='Mail'
              />
            </div>
            <textarea
              name=''
              id=''
              cols='30'
              rows='5'
              placeholder='Message'
              className=' resize-none w-full bg-slate-100 text-slate-500 mb-5 p-5'
            ></textarea>
            <div>
              <input
                type='submit'
                className='w-full cursor-pointer p-5 text-center bg-orange-500 text-white'
              />
            </div>
          </div>
        </form>
      </section>

      <section className=' h-[50vh] bg-slate-800 mt-24'>
        <img
          src='/5UAWECLJVFOXQS6RPEGA3XXU3M.jpg'
          alt='map'
          className=' h-full w-full object-cover'
        />
      </section>

      <footer className='  pt-14 pb-7 px-[5%]'>
        <div className='flex flex-col items-center gap-5 md:flex-row md:justify-between'>
          <div>
            <img src='/image001.png' className=' h-16' alt='logo' />
          </div>
          <ul className='flex gap-7 text-lg'>
            <li>Services</li>
            <li>A propos</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className=' border-t py-3 mt-10'>
          <p className='text-center text-sm text-slate-500'>
            By HMS-CO Technologie Copyright 2023
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
