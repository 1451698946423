import React from 'react';
import { BsFillChatRightFill } from 'react-icons/bs';

function Services() {
  return (
    <div>
      <div className='flex justify-center items-center h-24 w-24 text-3xl text-white bg-gray-900'>
        <BsFillChatRightFill />
      </div>
      <h4 className='text-xl font-semibold my-4'>Lorem ipsum dolor</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
  );
}

export default Services;
